import axios from 'axios';
import api from '@/services/api'
const state = {
    wishlistItems: [],
    courseWishlist:[],
    assetWishlist:[],
}
const mutations = {
    UPDATE_WISHLIST_ITEMS (state, payload) {
        state.wishlistItems = payload;
    },
}
const actions = {
    getWishlistItems ({ commit }, userID) {
        axios.get(api.getUrl('/user/wishlist/'+userID)).then((response) => {
            if(response.data.success){
                commit('UPDATE_WISHLIST_ITEMS', response.data.data)
            }
        });
    },
    addWishlistItem ({ commit }, item) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                axios.post(api.getUrl('/user/wishlist/store'), item).then((response) => {
                    if(response.data.success){
                        commit('UPDATE_WISHLIST_ITEMS', response.data.data)
                        resolve(response.data.data); // Resolve with fetched data
                    }else{
                        reject(response.data.data); // Reject on failure
                    }
                });
            }, 1000);
        });
    },
    removeWishlistItem ({ commit }, item) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                axios.post(api.getUrl('/user/wishlist/removed'),{ user_id:item.user_id, item_id:item.item_id, item_type:item.item_type }).then(function (response) {
                    if(response.data.success){
                        commit('UPDATE_WISHLIST_ITEMS', response.data.data)
                        resolve(response.data.data); // Resolve with fetched data
                    }else{
                        reject(response.data.data); // Reject on failure
                    }
                }).catch(()=>{});
            }, 1000);
        });
    },
}
const getters = {
    wishlistItems: state => state.wishlistItems,

    wishlistQuantity: state => {
        if(Array.isArray(state.wishlistItems)){
            return state.wishlistItems.reduce((acc) => {
                return 1 + acc;
            }, 0);
        }return 0;
    },
    wishlistItemSummary: state => {
        state.itemsSummary = [];
        if(Array.isArray(state.wishlistItems)){
            state.wishlistItems.forEach((value) => {
                let item = [];
                item.item_id        =  value.item_id;
                item.item_type      =  value.item_type;
                item.price          =  value.price;
                item.offer_price    =  value.offer_price;
                item.qty            =  value.quantity;
                state.itemsSummary.push(item);
            });
        }
        return state.itemsSummary;
    },
    courseWishlistItems: state => {
        if(Array.isArray(state.wishlistItems)){
            state.wishlistItems.forEach((value) => {
                if(value.item_type ==='course'){
                    state.wishlistCart.push(value.item_id);
                }
            });
        }
        return state.courseWishlist;
    },
    assetWishlistItems: state => {
        state.assetWishlist = [];
        if(Array.isArray(state.wishlistItems)){
            state.wishlistItems.forEach((value) => {
                if(value.item_type ==='asset'){
                    state.assetWishlist.push(value.item_id);
                }
            });
        }
        return state.assetWishlist;
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}