<template>
    <div>
        <footer class="siteFooter">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="footer-block">
                            <div class="row">
                                <div class="col-xl-6">
                                    <ul>
                                        <li></li>
                                    </ul>
                                </div>

                                <div class="col-xl-6">
                                    <p> &copy; Ajay {{currentDate}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
import store from "@/store"
export default {
    name:'Footer',
    data() {
      return {
        isAuthenticated:store.getters.authenticated,
        currentDate: new Date().getFullYear(),
      }
    },
}
</script>